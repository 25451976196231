import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";

export const PageNotFound = () => {
  const history = useHistory();

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection:"column",
          alignItems: "center",
        }}
      >
        <div style={{ fontSize: "2em" }}>😢 404 | Page not found</div>
        <Button
          variant="contained"
          size="small"
          sx={{ my: 2 }}
          onClick={() => history.push("/")}
        >
          Home
        </Button>
      </Box>
    </Box>
  );
};
