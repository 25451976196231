import React, { useEffect } from "react";
import useGlobalState from "./useGlobalState";
import Context from "./context";

const GlobalStateProvider = ({ children }: { children: React.ReactNode }) => {
  const { globalState, globalDispatch } = useGlobalState();

  useEffect(() => {
    sessionStorage.setItem("globalState", JSON.stringify(globalState));
  }, [globalState]);

  return (
    <Context.Provider value={{ globalState, globalDispatch }}>
      {children}
    </Context.Provider>
  );
};

export default GlobalStateProvider;
