import { lazy } from "react";
import CLIENT_ROUTES from "./client";
import { AppRoute } from "./interfaces";
import SUPPLIER_ROUTES from "./supplier";

const CSMServices = lazy(
  () => import("internal/client-service-managers/services")
);
const CSMTickets = lazy(
  () => import("internal/client-service-managers/tickets")
);

const AllClientServices = lazy(() => import("../components/CSM/WIOCCClients"));
const AllClientTickets = lazy(
  () => import("internal/tickets/AllClientTickets")
);

const APP_ROUTES: AppRoute[] = [
  {
    path: "/internal/csm/services",
    element: CSMServices,
  },
  {
    path: "/internal/csm/tickets",
    element: CSMTickets,
  },
  {
    path: "/internal/all-client-services",
    element: AllClientServices,
  },
  {
    path: "/internal/all-client-tickets",
    element: AllClientTickets,
  },

  ...CLIENT_ROUTES,
  ...SUPPLIER_ROUTES,
];

export default APP_ROUTES;
