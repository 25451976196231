import { useEffect, useState, useContext } from "react";
import Context from "../../store/context";
import { Route, Redirect } from "react-router-dom";
import MyLoader from "./MyLoader";
import { fetchJsonFromApiJsonResponse } from "./FetchFunctions";
import Cookies from "js-cookie";
import { isWIOCCEmployee } from "../../Utils/Util";

export function PrivateRoute({ component: Component, ...rest }) {
  const { globalState, globalDispatch } = useContext(Context);
  const [awaitingResponse, setAwaitingResponse] = useState(true);

  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    if (
      !globalState.userDetails ||
      (globalState.userDetails && globalState.userDetails.name === null)
    ) {
      const access_token = Cookies.get("access_token_uchawi");

      setAccessToken(access_token);
      if (awaitingResponse) {
        fetchJsonFromApiJsonResponse("getUserDetails")
          .then(({ user }) => {
            if (user.email === "") {
              throw new Error("No email address");
            }

            if (
              !user.supplier_group_id &&
              !user.supplier_id &&
              !user.client_group_id &&
              !user.client_id
            ) {
              if (!isWIOCCEmployee(user?.email)) {
                throw new Error("Invalid User Details");
              }
            }

            if (user.name) {
              globalDispatch({
                type: "LOGIN",
                accessToken: { accessToken },
                user: user,
              });

              setAwaitingResponse(false);
            } else {
              setAwaitingResponse(false);
            }
          })
          .catch((err) => {
            setAwaitingResponse(false);
            globalDispatch({ type: "LOGOUT" });
          });
      } else {
        setAwaitingResponse(false);
      }
    } else {
      setAwaitingResponse(false);
    }
    return () => setAwaitingResponse(false);
  }, [awaitingResponse, accessToken, globalDispatch, globalState.userDetails]);

  if (globalState.isLoggedIn === false && !awaitingResponse) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )}
      />
    );
  }

  if (globalState.isLoggedIn === true) {
    //todo: validate the user has access to the page they wish to go to using client_id and supplier id

    return (
      <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
    );
  }

  return <MyLoader />;
}
