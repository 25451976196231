import React from "react";
import { render } from "react-dom";
import App from "./App";
import { ToastProvider } from "react-toast-notifications";
import { BrowserRouter as Router } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "@fontsource/montserrat";

import "@fontsource/montserrat";
import "./css/index.css";
import ErrorBoundary from "common/error-boundary";

render(
  <Router>
    <ToastProvider autoDismiss={true} autoDismissTimeout={10000}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </ToastProvider>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
