import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import type { PaletteMode } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
// import colorPallete from "./color-palette";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

export const options: PaletteOptions = {
  primary: {
    dark: "#162561",
    main: "#20368b",
    light: "#4c5ea2",
  },
  secondary: {
    dark: "#9e0c1d",
    main: "#e3122a",
    light: "#e84154",
  },
};

export const getMuiTheme = (colorMode: PaletteMode) => {
  return createTheme({
    // palette: {
    //   mode: colorMode,
    //   primary: {
    //     main: colorPallete.primary[500],
    //   },
    //   secondary: {
    //     main: colorPallete.secondary[500],
    //   },
    //   // ...options,
    // },
    palette: { ...options, mode: colorMode },
    components: {
      MuiButton: {
        defaultProps: {
          sx: {
            minWidth: "auto",
            textTransform: "none",
            fontWeight: "bold",
          },
        },
      },
    },
    typography: {
      fontFamily: "Montserrat",
      button: {
        textTransform: "none",
      },
    },
    // shadows: shadows.map(() => '1') as Shadows,
  });
};

const muiCache = createCache({
  key: "mui",
  prepend: true,
});

const CustomMuiThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={getMuiTheme("light")}>{children}</ThemeProvider>
    </CacheProvider>
  );
};

export default CustomMuiThemeProvider;
