import React, { Component } from "react";
import Button from "react-bootstrap/Button";

import { apiPost } from "components/common/http";
import { Buffer } from "buffer";
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const data = { errorInfo, stack: `${error.stack}` };
    const strPayload = JSON.stringify(data);
    const payload = { data: Buffer.from(strPayload).toString("base64") };
    if (process.env.NODE_ENV === "production") {
      apiPost("log-error", payload);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: "flex",
            height: "100vh",
            width: "100vw",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <h1 style={{ fontSize: "1.8em" }}>Something Went Wrong</h1>
            <div className="d-flex">
              <Button
                size="sm"
                variant="primary"
                className="wiocc-btn mx-auto my-2"
                onClick={() => window.location.reload()}
              >
                Reload
              </Button>

              <Button
                size="sm"
                variant="primary"
                className="wiocc-btn mx-auto my-2"
                onClick={() => {
                  window.location = "https://portal.wiocc.net";
                }}
              >
                Go Home
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
