import React from "react";
// import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
// import logo from "../../images/logo-white-full.png";
import logo from "../../images/new_logo_caption_tiny.png";

// Can be a string as well. Need to ensure each key-value pair ends with ;
// const override = css`
//   border-color: white;
// `;

class MyLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#20368b",
          position: "fixed",
          textAlign: "center",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexFlow: "column",
        }}
      >
        <div>
          {/* <img src="/images/new_logo_caption.png" alt="Logo" height="45px" width="181px" /> */}
          <img src={logo} alt="Logo" height="45px" width="181px" />
        </div>
        <div>
          <BarLoader
            // css={override}
            width={150}
            color={"#ffffff"}
            loading={this.state.loading}
          />
        </div>
      </div>
    );
  }
}

export default MyLoader;
