import { PageNotFound } from "common/page-not-found";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import APP_ROUTES from "routes";
import LazyLoader from "./components/common/LazyLoader";
import { PrivateRoute } from "./components/common/PrivateRoute";
import GlobalStateProvider from "./store/GlobalStateProvider";
import CustomMuiThemeProvider from "./theme/mui-theme";

const Login = lazy(() => import("./components/login/LoginPage"));
const LogoutPage = lazy(() => import("./components/login/LogoutPage"));
const Admin = lazy(() => import("./components/Admin/Admin"));
const InternalAdmin = lazy(() =>
  import("./components/admin-internal/InternalAdmin")
);
const DashBoard = lazy(() => import("./components/dashboard/DashBoard"));

function App() {
  return (
    <GlobalStateProvider>
      <BrowserRouter>
        <CustomMuiThemeProvider>
          <Suspense fallback={<LazyLoader />}>
            <Switch>
              <Route path="/login" component={Login} />
              <PrivateRoute path="/" exact component={DashBoard} />

              {APP_ROUTES.map(({ path, element: Element }, i) => {
                return (
                  <PrivateRoute exact key={i} path={path} component={Element} />
                );
              })}

              <PrivateRoute path="/admin" exact component={Admin} />
              <PrivateRoute
                path="/admin-internal"
                exact
                component={InternalAdmin}
              />

              <PrivateRoute path="/logout" exact component={LogoutPage} />

              <Route path="*" component={PageNotFound} />
            </Switch>
          </Suspense>
        </CustomMuiThemeProvider>
      </BrowserRouter>
    </GlobalStateProvider>
  );
}

export default App;
