import { lazy } from "react";
import { AppRoute } from "./interfaces";

// const POS = lazy(() => import("components/Supplier/SupplierPOs"));
// const POWpns = lazy(() => import("components/stock/POWpns"));
// const POWpn = lazy(() => import("components/stock/POStock"));

const SupplierOnboarding = lazy(
  () => import("components/Supplier/onboarding/SupplierOnboarding")
);

// const HorizonProgrammes = lazy(
//   () => import("components/Supplier/horizons/HorizonProgrammes")
// );
// const HorizonProgramme = lazy(
//   () => import("components/Supplier/horizons/horizon-programme")
// );

const SupplierDashboard = lazy(
  () => import("components/Supplier/SupplierDashboard")
);

// const SupplierWPNs = lazy(
//   () => import("components/Supplier/WPNS/SupplierWPNs")
// );

const SUPPLIER_ROUTES: AppRoute[] = [
  {
    path: "/supplier",
    element: SupplierDashboard,
  },
  {
    path: "/supplier/onboarding",
    element: SupplierOnboarding,
  },
  // {
  //   path: "/supplier/pos",
  //   element: POS,
  // },
  // {
  //   path: "/supplier/pos/:po_name",
  //   element: POWpns,
  // },
  // {
  //   path: "/supplier/pos/:po_name/:wpn_name",
  //   element: POWpn,
  // },
  // {
  //   path: "/supplier/horizon-programmes",
  //   element: HorizonProgrammes,
  // },
  // {
  //   path: "/supplier/horizon-programmes/:horizonProgramme",
  //   element: HorizonProgramme,
  // },
  // {
  //   path: "/supplier/wpns",
  //   element: SupplierWPNs,
  // },
];

export default SUPPLIER_ROUTES;
