import { useReducer } from "react";
import { postDataToApiJsonResponse } from "../components/common/FetchFunctions";
import { ContextProps, GlobalState } from "./interfaces";

const reducer = (state: GlobalState, action: Record<string, any>) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        ...action.accessToken,
        ...action.user,
        isLoggedIn: true,
      };
    case "LOGOUT":
      sessionStorage.clear();
      localStorage.clear();
      return {
        ...state,
        isLoggedIn: false,
        user: {
          name: null,
          email: null,
          contact_name: null,
          client_group_id: null,
          supplier_group_id: null,
          client_group: null,
          supplier_group: null,
          is_admin: null,
        },
        priorityDict: { port: {} },
        theme: "light",
        filterDetails: {
          filterState: {},
          filterLabels: {},
          availableFilters: [],
        },
        fontSize: 13,
        window_height: window.outerHeight,
        window_width: window.outerWidth,
      };
    case "SETUSER":
      postDataToApiJsonResponse(
        "setUserNetsuiteDetails",
        action.userDetails
      ).catch((error) => {
        console.error("Error:", error);
      });
      return {
        ...state,
        userDetails: action.userDetails,
      };
    case "SETPRIORITY":
      return {
        ...state,
        priorityDict: action.priorityDict,
      };
    case "SETTHEME":
      return {
        ...state,
        theme: action.theme,
      };

    case "SETFILTERS":
      return {
        ...state,
        filterDetails: action.filterDetails,
      };
    case "SETFONTSIZE":
      return {
        ...state,
        fontSize: action.fontSize,
      };
    case "SETWINDOWDIMENSIONS":
      return {
        ...state,
        window_height: action.window_height,
        window_width: action.window_width,
      };
    case "SET_DRAWER_STATE":
      return {
        ...state,
        isDrawerOpen: action.payload.isDrawerOpen,
      };
    default: {
      return state;
    }
  }
};

const useGlobalState = (): ContextProps => {
  const persistedGlobalState = sessionStorage.getItem("globalState");
  const _globalState: GlobalState = persistedGlobalState
    ? JSON.parse(persistedGlobalState)
    : {
        isLoggedIn: null,
        priorityDict: { port: {} },
        theme: "light",
        filterDetails: {
          filterState: {},
          filterLabels: {},
          availableFilters: [],
        },
        fontSize: 13,
        isDrawerOpen: false,
        window_height: window.outerHeight,
        window_width: window.outerWidth,
      };

  const [globalState, globalDispatch] = useReducer(reducer, _globalState);

  return { globalState, globalDispatch };
};

export default useGlobalState;
