import { upperFirst, words } from "lodash";

const WIOCC_EMAIL_DOMAIN = "@wiocc.net";

/**
 *
 * @param {string} email email for currently logged in user
 * @returns boolean whethere user is a wiocc employee
 */
export const isWIOCCEmployee = (email = "") => {
  return email?.endsWith(WIOCC_EMAIL_DOMAIN);
};

/**
 *
 * @param {string} text text to make title case
 * @returns string|undefined
 */
export const titleText = (text, replaceStr = " ") => {
  if (!text) return text;
  return words(text.replace(/_/g, replaceStr))
    .map((word) => upperFirst(word))
    .join(replaceStr);
};

/**
 * @param {object} obj object in which you want to replace null or undefined values
 * @param {string} replaceValue what you want to replace them with
 * @returns JSON object
 */
export const replaceNull = (obj, replaceValue = "") => {
  const replacer = (key, value) => {
    return String(value) === "null" || String(value) === "undefined"
      ? replaceValue
      : value;
  };

  return JSON.parse(JSON.stringify(obj, replacer));
};
